import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { companyService } from "@/services/company";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useCompany() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "company", sortable: true, label: "บริษัท" },
    { key: "email", sortable: true, label: "อีเมล์" },
    { key: "status", sortable: true, label: "สถานะอัพโหลดเอกสาร" },
    { key: "actions", label: "การจัดการ" },
  ];

  const fetchUsers = ref([]);
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  const onClickSearch = () => {
    getCompany();
  };

  onMounted(() => {
    getCompany();
  });

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const buildDefaultParams = () => {
    const filter = {
      page: currentPage.value,
      size: perPage.value,
      ...(searchQuery.value !== "" ? { searchQuery: searchQuery.value } : {}),
    };

    return new URLSearchParams(filter).toString();
  };

  const getCompany = () => {
    const queryParams = buildDefaultParams();
    companyService
      .fetchData(queryParams)
      .then((response) => {
        const { data, pagination } = response;
        totalUsers.value = 0;

        if (data) {
          totalUsers.value = pagination.totalItems;
          fetchUsers.value = data;
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
    onClickSearch,
  };
}
